.update-system-page {
    width: 100%;

    &__wrapper {
        background-color: #f3f3f3;
        width: 100%;
        min-height: 30vh;
        padding: 30px;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
    }

    &__export-block {
        display: flex;
        flex-direction: column;

        h1 {
            font-size: 28px;
            margin-bottom: 5px;
        }

        &-list {
            width: 100%;
            min-height: 200px;
            border-radius: 10px;
            background-color: #ffffff;
            padding: 30px;

            &__item {
                min-width: 200px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                margin-bottom: 10px;
                padding: 5px;
                border-radius: 10px;


                background-color: #dbdbdb;

                &-name {

                }

                &-icon {
                    width: 27px;
                    height: 27px;
                    cursor: pointer;
                }
            }

            &__placeholder {
                color: #cecece;
            }
        }

        &-buttons {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__import-block {
        display: flex;
        flex-direction: column;
    }
}
