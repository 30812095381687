
.email-smtp-container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.email-smtp-form {
    min-width: 300px;
    max-width: 400px;
    border-radius: 10px;
    border: 2px solid #eeeeee;
    margin-right: 20px;
}

.email-smtp-header{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #5664d2;
    height: 50px;
    color: white;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    font-family: 'Roboto';
}

.email-smtp-inputs{
    padding: 20px;
}
.email-smtp-button{
    width: 100%;
    margin-top: 25px;
}
.email-smtp-helperMess{
    font-family: 'Roboto';
    font-size: 12px;
    color: #b1b0b0;
    margin: 5px 0 0 15px;
}

@media (max-width: 1285px) {
    .email-smtp-form{
        margin-top: 10px;
        margin-right: 0px;
    }
    .email-smtp-container{
        flex-direction: column;
    }

}
